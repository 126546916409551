<template>
  <introtext>
    <div class="intro">
      Welcome to my website!
      <br><br>
      My name is Robin; I am a passionate game developer with expertise in C++ and Unreal Engine 5. I am currently a Gameplay Engineer at Relic Entertainment, an Electronic Arts studio. 
      With a strong background in physics and computer science, I bring a unique blend of technical knowledge and creativity to my work!
      <br><br>
      Throughout my career, I had the privilege of working with renowned studios like Relic Entertainment (SEGA), The Coalition (Microsoft Xbox), and Ubisoft. 
      You can learn more about my experience by reading my <a href="https://robinleman.com/robin-leman-resume.pdf" target="_blank">Resume</a>.
      <br><br>
      I hold a Bachelor of Science in Physics and Computer Science from McGill University. As an avid learner, I have worked on personal projects pushing my knowledge of game development. 
      Working with an astrophysics lab at McGill, I developed <a href="https://github.com/RobinLmn/DebrisDiskSimulation" target="_blank">a 3D rendering engine</a> to visualize debris disks in solar systems. 
      This real time tool utilized my knowledge of 3D game engines to advance crucial research on exoplanets. 
      I also designed a 3D Physics Engine, <a href="https://robinleman.com/achilles.html" target="_blank">Achilles</a>, using an Entity-Component-System architecture. 
      This physics simulation tool supports newtonian dynamics, collision detection and response, and softbody simulation. 
      <br><br>
      In addition to my technical expertise, I am actively involved in the game development community. As the former President of GameDev McGill, I led a team to organize events for over 
      100 members of our game development club. One of our highlights was organizing McGame Jam, a 48-hour hackathon that brought together more than 150 hackers to create amazing games. 
      I am also a dedicated volunteer with the Red Cross, where I served as an emergency responder, certified in Emergency First Aid CPR (Level C).
      <br><br>
      If you are looking for a passionate and dedicated game developer with a strong foundation in C++ and Unreal Engine 5, I would love to connect and discuss how I can contribute to your team. 
      Feel free to reach me through <a href="https://www.linkedin.com/in/robin-leman/" target="_blank">LinkedIn</a> or by <a href="mailto:jobs@robinleman.com" target="_blank">e-mail</a>.
      <br><br>
      Thank you for visiting, and I look forward to exciting opportunities ahead!
   </div>
  </introtext>
</template>

<script>
export default {
  name: 'IntroText',
  components: {
  }
}
</script>

<style>
@import '../style.css';
</style>